<template>
  <div>
    <div class="padding_top"></div>

    <b-container>
      <b-row align-v="center" align-h="center">
        <p class="navbar__user_modal__label">Регистрация</p>
      </b-row>

      <div class="modal__center">
        <b-row class="modal__form_wrapper" align-h="center" align-v="center">
          <b-col cols="12" md="4">
            <b-form @submit="register" class="modal__form">
              <b-form-group>
                <label class="register_label" for="login">Логин</label>

                <b-form-input
                  id="login"
                  :state="loginState"
                  trim
                  v-model="form.login"
                  type="text"
                  placeholder="eeone"
                  aria-describedby="loginFeedback"
                ></b-form-input>

                <p class="invalid_feedback" v-if="errors.login">
                  {{ errors.login[0] }}
                </p>
                <p class="invalid_feedback" v-if="back_err.login">
                  {{ back_err.login[0] }}
                </p>
              </b-form-group>

              <b-form-group>
                <label class="register_label" for="name">Имя*</label>

                <b-form-input
                  id="name"
                  v-model="form.name"
                  type="text"
                  placeholder="Иван"
                  class="required_input"
                  required
                ></b-form-input>

                <p class="invalid_feedback" v-if="back_err.name">
                  {{ back_err.name[0] }}
                </p>
              </b-form-group>

              <b-form-group>
                <label class="register_label" for="email">E-mail*</label>

                <b-form-input
                  id="email"
                  v-model="form.email"
                  type="email"
                  placeholder="email@example.com"
                  required
                  class="required_input"
                ></b-form-input>

                <p class="invalid_feedback" v-if="errors.email">
                  {{ errors.email[0] }}
                </p>
                <p class="invalid_feedback" v-if="back_err.email">
                  {{ back_err.email[0] }}
                </p>
              </b-form-group>

              <b-form-group>
                <label class="register_label" for="password">Пароль*</label>

                <b-form-input
                  id="password"
                  type="password"
                  :state="passwordState"
                  v-model="form.password"
                  required
                  class="required_input"
                  aria-describedby="passwordFeedback"
                ></b-form-input>

                <p class="invalid_feedback" v-if="errors.password">
                  {{ errors.password[0] }}
                </p>
                <p class="invalid_feedback" v-if="back_err.password">
                  {{ back_err.password[0] }}
                </p>
              </b-form-group>

              <b-form-group>
                <label class="register_label" for="password_confirmation"
                  >Пароль ещё раз*</label
                >

                <b-form-input
                  id="password_confirmation"
                  type="password"
                  v-model="form.password_confirmation"
                  aria-describedby="passwordConfirmFeedback"
                  required
                  class="required_input"
                  :state="passwordConfirmationState"
                ></b-form-input>

                <p class="invalid_feedback" v-if="errors.password_confirmation">
                  {{ errors.password_confirmation[0] }}
                </p>
                <p
                  class="invalid_feedback"
                  v-if="back_err.password_confirmation"
                >
                  {{ back_err.password_confirmation[0] }}
                </p>
              </b-form-group>

              <p v-if="$route.query.ref">
                Вас пригласил(а): {{ $route.query.ref }}
              </p>

              <button
                type="submit"
                class="main__button modal_form__buttom register_button"
                :disabled="disableButtonState == true ? true : loading"
              >
                <p class="button_text">Зарегистрироваться</p>
              </button>
            </b-form>
          </b-col>
        </b-row>
      </div>
    </b-container>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "Register",
  data() {
    return {
      form: {
        name: "",
        login: "",
        email: "",
        password: "",
        password_confirmation: "",
      },

      errors: {
        login: [],
        name: [],
        email: [],
        password: [],
        password_confirmation: [],
      },

      back_err: {
        login: [],
        name: [],
        email: [],
        password: [],
        password_confirmation: [],
      },
    };
  },
  computed: {
    user() {
      return this.$store.state.user.user;
    },
    loading() {
      return this.$store.state.loading;
    },
    disableButtonState() {
      let result = false; // disable button

      if (
        this.loginState == false ||
        this.passwordState == false ||
        this.passwordConfirmationState == false
      ) {
        result = true;
      }

      return result;
    },
    loginState() {
      let result = null;

      if (this.form.login.length != 0) {
        result = true;
        this.errors.login = [];

        if (
          (this.form.login.length >= 3 && this.form.login.length <= 25) == false
        ) {
          result = false;
          this.errors.login = ["Логин должен содержать от 3 до 25 символов"];
        }
      }

      return result;
    },
    passwordState() {
      let result = null;
      this.errors.password = [];

      if (this.form.password.length != 0) {
        result = true;
        if (this.form.password.length < 8) {
          result = false;
          this.errors.password = ["Минимальная длина пароля 8 символов"];
        }
      }

      return result;
    },
    passwordConfirmationState() {
      let result = null;
      this.errors.password_confirmation = [];

      if (this.form.password_confirmation.length != 0) {
        result = true;

        if (this.form.password != this.form.password_confirmation) {
          result = false;
          this.errors.password_confirmation = ["Пароли не совпадают"];
        }
      }

      return result;
    },
  },
  methods: {
    async register(ev) {
      ev.preventDefault();

      this.back_err = {
        login: [],
        name: [],
        email: [],
        password: [],
        password_confirmation: [],
      };

      let ref = this.$route.query.ref;

      try {
        this.$store.commit("SET_LOADING", true);

        await axios.get(this.$store.state.csrfUrl);

        await axios.post("register", {
          name: this.form.name,
          login: this.form.login,
          email: this.form.email,
          password: this.form.password,
          password_confirmation: this.form.password_confirmation,
          ref: ref,
        });

        await this.$store.dispatch("login", this.form);

        this.$router.push({ name: "Home" });

        this.$store.commit("SET_LOADING", false);
      } catch (e) {
        this.$store.commit("SET_LOADING", false);

        this.back_err = e.response.data.errors;
      }
    },
  },
};
</script>

<style lang="scss">
.error_login {
  color: $accent;
}
.button_text {
  margin: 0 auto;
}

.register_button {
  margin-top: 30px;
}

.register_button:disabled {
  filter: brightness(70%) saturate(0%);
}

.invalid_feedback {
  font-size: 14px;
  color: $accent;
  margin: 0;
}

.required_input {
  border-color: rgba($color: $white, $alpha: 0.6);
}

.register_label {
  color: rgba($color: $white, $alpha: 0.6);
}
</style>
